<div class="card card-item" *ngIf="breadcrumb && breadcrumb.length > 0">
	<div class="custom-bread-crumb flex-container">
		<div class="col-12 col-md-10">
			<span *ngFor="let item of breadcrumb; let i = index">
				<li [ngStyle]="{ 'font-size': fontSize }">
					<a *ngIf="item?.url" [routerLink]="item?.url" [ngStyle]="{ color: fontColor }" [queryParams]="item.queryParams">{{ item.label }}</a>
					<span *ngIf="!item?.url" [ngStyle]="{ color: lastLinkColor }">{{ item.label }}</span>
					<span class="line" *ngIf="breadcrumb.length !== i + 1">{{ symbol }}</span>
				</li>
			</span>
		</div>
		<div class="col-12 col-md-2 div-right">
			<a *ngIf="showEyeIcon" (click)="eyeIconClicked()" title="Event Details">
				<i class="fas fa-eye icon eye-icon"></i>
			</a>
			<a #shareIcon (click)="onShareClick()" title="Share">
				<i class="bx bx-sm icon bx-share-alt"></i>
			</a>
			<a *ngIf="showFavoriteIcon" (click)="OnFavoriteClick()" title="Add To Favorites">
				<i class="bx bx-sm icon" [ngClass]="isFavorite ? 'bxs-star' : 'bx-star'"></i>
			</a>
			<a *ngIf="showEditIcon" (click)="editIconClicked()" title="Edit">
				<i class="bx bx-sm icon bx-edit"></i>
			</a>
		</div>
		<div class="share-sec" #shareModal *ngIf="showShareSection">
			<div class="share-sec-main">
				<div class="share-header-sec">
					<span>Share link via</span>
					<div title="close" class="close" (click)="closeShare()"><i class="fa fa-times" aria-hidden="true"></i></div>
				</div>
				<div class="share-medium-fbox">
					<div>
						<a title="Email"><img width="40px" src="../../../../../assets/images/email-share.png" (click)="openMail()" alt="" /></a>
					</div>
					<div>
						<a title="Teams"><img width="40px" src="../../../../../assets/images/teams-share.png" (click)="openTeams()" alt="" /></a>
					</div>
					<div>
						<a title="Copy Link"><img width="40px" src="../../../../../assets/images/link-share.png" (click)="openCopyLink()" alt="" /></a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
